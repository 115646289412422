<template>
  <div class="activation">
    <div style="height: 322px" class="EchatsBox">
      <div :id="EchatsId" class="EchatsImg" style="height: 322px"></div>
<!--      <div class="back" style=" width: 150px;-->
<!--  height: 150px;"></div>-->
    </div>
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";

export default {
  props: {
    EchatsId: {
      type: [String, Number],
      default: "main",
    },
    deviceName: {
      type: Array,
    },
    seriesData: {
      type: Array,
    },
    color: {
      type: Array,
      default: () => ['#3173F1', '#FD991F', '#33C389', '#F8CC2C',  '#45BFF7', '#71C33A', '#4C50EE', '#7950E5', '#E54C91', '#EB4F4E']
    }
  },
  data() {
    return {
      myChart: undefined,
      seriesDataList: [],
    };
  },
  watch: {
    seriesData: {
      // immediate:true,
      deep: true,
      handler(newValue) {
        this.seriesDataList = newValue;
        this.myEcharts();
      }
    },
  },
  mounted() {
    this.myEcharts();
  },
  methods: {
    myEcharts() {
      this.seriesDataList = [];
      this.seriesDataList = this.seriesData;
      if (this.myChart) {
        this.myChart.dispose()
      }
      const chartList = document.getElementById(this.EchatsId);
      this.myChart = this.$echarts.init(document.getElementById(this.EchatsId));
      const option = {
        legend: {
          type:'scroll',
          pageIconSize:[10,10],
          show: true,
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            // fontWeight: "lighter",
            width: 80,
            overflow: "truncate",
          },
          right: 5,
          top: "middle",
          orient: "vertical",
          data: this.deviceName,
        },
        // 颜色
        color: this.color,
        polar: {
          center: ["35%", "55%"],
          radius: ['0%', '65%']
        },
        angleAxis: {
          max: 100,
          startAngle: 90,
          splitNumber: 5,
          axisLine: {
            show: false,
            splitNumber:1
          },
          axisLabel: {
            fontWeight: "bold",
            formatter: "{value}%",
          },
          // type:'time'
        },
        radiusAxis: {
          type: "category",
          show: false,
          max: 0,
        },
        tooltip: {},
        series: this.seriesDataList,
      };
      if (this.seriesDataList.length <= 0) {
        this.myChart.dispose();
        this.myChart = undefined
        return;
      }
      this.$nextTick(() => {
        this.myChart.setOption(option);
        window.addEventListener('resize', () => {
          this.myChart.resize();
        });
        // 图表的自适应
        elementResizeDetectorMaker().listenTo(chartList, () => {
          this.myChart.resize();
        });
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.EchatsBox {
  z-index: 0;
  //background: #ffffff;
  //width: 400px;
  height: 270px;
  //box-shadow: 1px 2px 9px 1px rgba(215, 215, 215, 0.6);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
}
.back {
  position: absolute;
  top: 27.5%;
  left: 20%;
  background-image: url("~@/assets/img/back-echarts.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.EchatsImg {
  width: 97%;
  height: 320px;
  text-align: center;
  line-height: 202px;
  z-index: 1;
  //margin-left: 35px;
  overflow-y: hidden;
  //overflow-y: hidden;
}
.contentText {
  line-height: 36px;
  font-weight: bold;
  font-size: 16px;
  color: #281315;
}
@media screen and (max-width:800px) {
  .EchatsBox {
    width: 100%;
    height: 900px !important;
  }
  .EchatsImg {
    width: 97%;
    height: 900px !important;
  }
}
</style>
