<template>
  <!--线型图-->
  <div>
    <div :id="id" :style="`height: ${height}px`" class="chart" />
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";

export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart',
    },
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 图表数据
    data: {
      type: Object,
      default: () => {},
    },
    // 图表高度
    height: {
      type: Number,
      default: 200,
    },
    // 图表区域上侧边距
    gridTop: {
      type: Number,
      default: 25,
    },
    // 图表区域左侧边距
    gridLeft: {
      type: Number,
      default: 20,
    },
    // 图表区域右侧边距
    gridRight: {
      type: Number,
      default: 20,
    },
    // 图表区域下侧边距
    gridBottom: {
      type: Number,
      default: 10,
    },
    // true：曲线，false：折线
    smooth: {
      type: Boolean,
      default: true,
    },
    // 图表类型
    type: {
      type: String,
      default: 'line',
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: true,
    },
    // 是否显示x轴
    show: {
      type: Boolean,
      default: true,
    },
    // 线条颜色
    linecolor: {
      type: Array,
      default: () => ['#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864'],
    },
    // y轴名称
    yAxisName: {
      type: String,
      default: ''
    },
    // 平均线
    markLine: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 图表配置
      optionEcharts: {
        // 提示框
        tooltip: {
          trigger: 'axis',
          confine: true, // 限制在图表区域内
        },
        title: {
          text: this.title,
          fontSize: '14',
          color:'#333',
          fontWeight: '400',
          left: 'center',
          bottom: 0
        },
        // 图例
        legend: {
          show: this.legendShow,
          itemWidth: 8,
          itemHeight: 8,
          // itemGap: 20,
          // orient: 'vertical',
            textStyle: {
              // color: '#fff',
              // fontSize: 14
            // color: '#ffffff'
          },
          // x: 'right',
          // y: '0%',
          right: '0%',
        },
        // 数据集
        dataset: {
          source: this.data.source,
        },
        // 图表区域大小
        grid: {
          top:this.gridTop,
          left: this.gridLeft,
          right: this.gridRight,
          bottom: this.gridBottom,
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: 'category',
          show: this.show,
          splitLine: {
            show: true
          }
        },
        // y轴
        yAxis: {
          type: 'value',
          name: this.yAxisName, // y轴名称
          offset: 0, // 偏移
          position: 'left', // 位置
          axisLabel: {
            formatter: '{value}',
          },
          nameTextStyle: {
            // color: '#fff'
          },
          // 刻度线
          axisTick: {
            show: false,
          },
          // 坐标轴线
          axisLine: {},
          // x轴网格线颜色
          splitLine: {},
        },
        // 系列
        series: [],
      },
    };
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        // console.log(val)
        this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      // 提示框
      this.optionEcharts.tooltip = this.data.tooltip || this.optionEcharts.tooltip;
      // y轴数据
      this.optionEcharts.yAxis = this.data.yAxis || this.optionEcharts.yAxis;
      // 系列
      this.data.series.forEach((item, index) => {
        this.optionEcharts.series.push({
          type: item.type, // 图表类型
          markLine: item.markLine, // 平均线
          yAxisIndex: item.yAxisIndex, // 有多条y轴时，设置y轴索引
          color: item.color || this.linecolor[index], // 线颜色
          smooth: this.smooth, // 曲线、折线
          symbolSize: '5', // 折点大小
          symbol: 'circle',
        });
      });
    },
    initChart() {
      // const char = this.$echarts.init(document.getElementById(this.id))
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
        // 图表的自适应
        elementResizeDetectorMaker().listenTo(chartList, () => {
          chart.resize();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .chart{
    min-width: 250px;
    width: 100%;
  }
  @media screen and (max-width:800px) {

  }
</style>
