<template>
  <div id="box999">
    <div class="box">
      <div class="header-box">
        <ul class="head">
          <li id="logo">
            <img class="img" src="~@/assets/img/Slice 19.png" @click="ToHomepage" alt="" />
          </li>
        </ul>
        <ul class="head" style="margin-right: 15px">
          <li v-if="userInfo.teamId" class="nav" @click="handleIcon">
            <div class="FristPage">
              印刷管家
            </div>
            <img class="icon-order" src="~@/assets/img/icon-order.png" alt="">
          </li>
        </ul>

        <div @click="handleIcon1" class="right-box">
<!--          <div class="name">-->
<!--            <span style="color:#F3A000">{{ userInfo.userName }}</span>-->
<!--          </div>-->
          <!--          <el-tooltip class="item" effect="dark" :content="userInfo.userName" placement="bottom">-->
          <!--            <div class="name">-->
          <!--&lt;!&ndash;              您好！用户&ndash;&gt;-->
          <!--&lt;!&ndash;              <span style="color:#F3A000">{{ userInfo.userName }}</span>&ndash;&gt;-->
          <!--            </div>-->
          <!-- </el-tooltip> -->
          <el-dropdown>
            <div class="block">
              <el-avatar
                  :size="30"
                  :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
              ></el-avatar>
              <!--              <el-avatar :size="54" :src="require(`@/assets/img/headerImg.png`)"></el-avatar>-->
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 150px;padding: 10px 0px 0px;font-size: 12px">
              <ol>
                <li class="li-text li1" @click="exit">
                  <div style="display: flex;padding: 10px 24px; margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 37.png" alt="img" />
                    </div>
                    退出登录
                  </div>
                </li>
              </ol>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="menu-app" :class="iconImg === true ? 'activeImg' : ''">
          <ul class="app-ul">
            <li>
              <router-link :to="`/PrintSteward/PrintHome`">
                <img src="~@/assets/img/icon-home1.png" alt="">
                主页
              </router-link>
            </li>
            <li >
              <router-link :to="`/PrintSteward/boss`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示老板
              </router-link>
            </li>
            <li>
              <router-link :to="`/PrintSteward/workshop`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示车间
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div @mouseenter="handleMouse" @mouseleave="handleLeave">
        <div class="title">印刷管家数据运营大屏</div>
        <div class="title fullScreen" :class="{active2: fullScreenTrue === true }" style="position: absolute;right: 30%; top: 30px" @click="click">
          <img src="@/assets/img/fullscreenBig.png" v-if="isFullscreen" alt="">
          <img src="@/assets/img/screenful.png" v-if="!isFullscreen" alt="">
          <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" />-->
        </div>
        <div class="selected-time">
          <ul>
            <li @click="handleDay(1)" :style="{'background': show === 1 ? '#3173F1' : '#ecf7ff','color': show === 1 ? '#fff' : '#3173F1'}">日</li>
            <li @click="handleDay(2)" :style="{'background': show === 2 ? '#3173F1' : '#ecf7ff','color': show === 2 ? '#fff' : '#3173F1'}">周</li>
            <li @click="handleDay(3)" :style="{'background': show === 3 ? '#3173F1' : '#ecf7ff','color': show === 3 ? '#fff' : '#3173F1'}">月</li>
          </ul>
        </div>
        <div class="timeYear">
          {{ nowDateList }}
        </div>
        <div class="time">
          {{ nowDate }}
        </div>

      </div>

      <div @click="handleIcon1" class="boss">
        <div :class="show === 1 ? 'active' : 'activeNone'">
          <div class="echarts-top" @mouseenter="handleMouse" @mouseleave="handleLeave">
            <div class="max-width-first" >
              <div class="title-module11 title-module10">
                <div>印刷质量</div>
                <div>PRINTING QUALITY</div>
              </div>
              <div class="swiper-top" style="display: inline-block;background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;width: 100%;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <div class="swiper swiper-container" id="swiper_fa3">
                  <div class="swiper-wrapper swiper-wrapper2" id="swiper_fa31" >
                    <div class="swiper-slide" v-for="(item, index) in letterQuality" :key="index">
                      <RingChart2
                          style="padding: 10px 0"
                          v-if="item.chartData.length > 0"
                          :id="'chart1' + index"
                          :data="item.chartData"
                          :legendRight="'auto'"
                          :legendTop="'0%'"
                          :height="'300px'"
                          :totalTop="'52%'"
                          :legendFontSize="15"

                          :title="item.name"
                          :titleTop="'bottom'"
                          :right="'center'"
                      ></RingChart2>
                    </div>
                  </div>
                  <div v-if="letterQuality.length < 1" class="no-data-top">暂无数据</div>
                  <!--            <div class="swiper-button-next"></div>-->
                  <!--            <div class="swiper-button-prev"></div>-->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>时间稼动率</div>
                <div>TIME OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="timeOeeList.length > 0"
                    :deviceName="deviceName"
                    :seriesData="seriesData"
                    :EchatsId="'EchatsId1'"
                ></activation>
                <div v-if="timeOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
            <div style="margin-right: 0" class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>性能稼动率</div>
                <div class="width-top6">PERFORMANCE OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="capacityOeeList.length > 0"
                    :deviceName="deviceName2"
                    :seriesData="seriesData2"
                    :EchatsId="'EchatsId2'"
                ></activation>
                <div v-if="capacityOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
          </div>
          <div class="echarts-top">
            <div class="height-503">
              <div class="title-module12 title-module10">
                <div>异常待机原因</div>
                <div class="width-top3">ABNORMAL STANDBY CAUSE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;height: 100%;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);overflow-y: auto;" class="aaa">
                <div >
                  <div id="carList">
                    <div v-for="(item, index) in abnormalInfo" :key="index" class="reason" style="margin:  15px;border-bottom: 1px solid #C4DEFE">
                      <RingChart
                          v-if="item.chartData.length > 0"
                          :id="'chart5' + index"
                          :data="item.chartData"
                          :legendRight="'5%'"
                          :legendTop="'5%'"
                          :height="'250px'"
                          :center="['35%', '40%']"
                          :totalTop="'36%'"
                          :legendFontSize="15"
                          :legendOrient="'vertical'"
                          :totalLeft="'-15%'"
                          :total="item.name"
                      ></RingChart>
                    </div>
                    <div class="no-data" v-if="abnormalInfo.length < 1"> 暂无数据</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="water">
              <div class="water-box" style="">
                <div class="water-top" style="">
                  <div class="title-module12 title-module10">
                    <div class="width-title">水墨平衡测试报告</div>
                    <div class="width-top">INK BALANCE TEST REPORT</div>
                  </div>
                  <div class="style-shui">
                    <div v-if="formDate.name">
                      <div class="painting">
                        <div class="name">
                          设备名称
                          <el-tooltip class="item" effect="dark" :content="formDate.name" placement="top-start">
                          <div>{{formDate.name}}</div>
                          </el-tooltip>
                        </div>
                        <div class="name">测评时间 <div>{{ formDate.date }}</div></div>
                      </div>
                      <div style="text-align: center">
                        <img v-if="formDate.result === 0" style="width: 130px" src="@/assets/img/cha.png" alt="">
                        <img v-if="formDate.result === 1" style="width: 130px" src="@/assets/img/zhong.png" alt="">
                        <img v-if="formDate.result === 2" style="width: 130px" src="@/assets/img/fine.png" alt="">
                        <img v-if="formDate.result === 3" style="width: 130px" src="@/assets/img/you.png" alt="">
                      </div>
                    </div>
                    <div v-else class="no-data">
                      暂无数据
                    </div>
                  </div>
                </div>
                <div class="water-bottom" style="">
                  <div class="title-module15 title-module10">
                    <div class="width-title2">印刷保养完成情况</div>
                    <div class="width-top2">COMPLETION OF PRINTING MAINTENANCE</div>
                  </div>
                  <div style="background-color: rgba(255,255,255,0.4);box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-231">
                    <div style="height: 100%;overflow-y: auto;" class="aaa">
                      <div v-if="formDate.maintainList.length > 0">
                        <div class="device-box" v-for="(item ,index) in formDate.maintainList" :key="index">
                          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                            <div class="deviceTitle">
                              {{ item.name }}
                            </div>
                          </el-tooltip>
                          <div class="deviceCenter">
                            <div class="device-matter" style="background-color: rgba(235, 246, 254,1)">
                              <span>印刷</span>
                              <span>保养完成率</span>
                              <span>点检完成率</span>
                            </div>
                            <div class="device-matter device-matter3" v-for="(line,indexLine) in item.mainList" :key="indexLine">
                              <span>{{ line.type === 1 ? '日保' : line.type === 2 ? '周保' : line.type === 3 ? '双周保' : line.type === 4 ? '半月保' : line.type === 5 ? '月保' : line.type === 6 ? '季度保' : line.type === 7 ? '半年度' : '年度' }}</span>
                              <span style="color: #3576F2;">{{ line.value1 }}%</span>
                              <span style="color: #3576F2;">{{ line.value2 }}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="device-matter device-matter5 device-matter4 no-data-list" style="padding: 25px 0;text-align: center">
                        暂无数据
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  class="forward-box">
                <div class="forward-top" style="">
                  <div class="title-module14 title-module10">
                    <div class="width-title3">异常待机提报</div>
                    <div class="width-top5">ABNORMAL STANDBY REPORT</div>
                  </div>
                  <div>
                    <div style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="abnormal">
                      <div class="device-matter device-matter1">
                        <span>异常提报</span>
                        <span>触发次数</span>
                        <span>填报次数</span>
                        <span>遗漏次数</span>
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.abnormalList.length > 0" :style='`height: ${heightAbnormalList}px;`' id="abnormalList1" >
                            <div class="device-matter device-matter2" v-for="(item, index) in formDate.abnormalList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}</span>
                              <span style="color: #3576F2;">{{ item.value3 }}</span>
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="" class="forward-bottom">
                  <div class="title-module title-module10">
                    <div>水箱报警</div>
                    <div>WATER TANK ALARM</div>
                  </div>
                  <div>
                    <div class="abnormal" style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                      <div class="device-matter device-matter4">
                        <span>水箱数据</span>
                        <span>异常触发</span>
                        <span>提报完成率</span>
<!--                        <span>审批完成率</span>-->
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.waterList.length > 0">
                            <div class="device-matter device-matter5 device-matter4" v-for="(item, index) in formDate.waterList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}%</span>
<!--                              <span>{{ item.value3 }}%</span>-->
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pring">
              <div class="title-module13 title-module10">
                <div>水箱监控</div>
                <div class="width-top4">WATER TANK MONITORING</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-501">
                <div style="height: 100%;overflow-y: auto;" class="aaa">
                  <div>
                    <div v-for="(item, index) in formDate.shuiXiangList" :key="index" class="aaa">
                      <div v-if="item.valueList.length > 0">
                        <LineChart
                            style="padding-top: 15px"
                            v-if="item.chartData.source.length > 0"
                            :id="'deviceChartWaterTank' + index"
                            ref="'deviceChartWaterTank'"
                            :data="item.chartData"
                            :linecolor="['#0acf97','#006df0','#fc7f03']"
                            :smooth="false"
                            :height="282"
                            :title="item.name"
                            :gridBottom="30"
                            :gridTop="30"
                            :gridLeft="50"
                            :show="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="formDate.shuiXiangList.length < 1" class="no-data no-data-line" style="">暂无数据</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="show === 2 ? 'active' : 'activeNone'">
          <div class="echarts-top" @mouseenter="handleMouse" @mouseleave="handleLeave">
            <div class="max-width-first">
              <div class="title-module11 title-module10">
                <div>印刷质量</div>
                <div>PRINTING QUALITY</div>
              </div>
              <div class="swiper-top" style="display: inline-block;background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;width: 100%;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <div class="swiper swiper-container" id="swiper_fa4">
                  <div class="swiper-wrapper swiper-wrapper2">
                    <div class="swiper-slide" v-for="(item, index) in letterQuality" :key="index">
                      <RingChart2
                          style="padding: 10px 0"
                          v-if="item.chartData.length > 0"
                          :id="'chartShow2' + index"
                          :data="item.chartData"
                          :legendRight="'auto'"
                          :legendTop="'0%'"
                          :height="'300px'"
                          :totalTop="'52%'"
                          :legendFontSize="15"
                          :width="'335px'"
                          :title="item.name"
                          :titleTop="'bottom'"
                          :right="'center'"
                      ></RingChart2>
                    </div>
                  </div>
                  <div v-if="letterQuality.length < 1" class="no-data-top">暂无数据</div>
                  <!--            <div class="swiper-button-next"></div>-->
                  <!--            <div class="swiper-button-prev"></div>-->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>时间稼动率</div>
                <div>TIME OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="timeOeeList.length > 0"
                    :deviceName="deviceName"
                    :seriesData="seriesData"
                    :EchatsId="'EchatsIdShow2'"
                ></activation>
                <div v-if="timeOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
            <div style="margin-right: 0" class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>性能稼动率</div>
                <div class="width-top6">PERFORMANCE OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="capacityOeeList.length > 0"
                    :deviceName="deviceName2"
                    :seriesData="seriesData2"
                    :EchatsId="'EchatsId2Show2'"
                ></activation>
                <div v-if="capacityOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
          </div>
          <div class="echarts-top">
            <div class="height-503">
              <div class="title-module12 title-module10">
                <div>异常待机原因</div>
                <div class="width-top3">ABNORMAL STANDBY CAUSE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;height: 100%;overflow-y: auto;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="aaa">
                <div>
                  <div id="carList2">
                     <div v-for="(item, index) in abnormalInfo" class="reason" :key="index" style="margin:  15px;border-bottom: 1px solid #C4DEFE">
                  <RingChart
                      v-if="item.chartData.length > 0"
                      :id="'chartShow' + index"
                      :data="item.chartData"
                      :legendRight="'5%'"
                      :legendTop="'5%'"
                      :height="'250px'"
                      :center="['35%', '40%']"
                      :totalTop="'36%'"
                      :legendFontSize="15"
                      :legendOrient="'vertical'"
                      :totalLeft="'-15%'"
                      :total="item.name"
                  ></RingChart>
                </div>
                    <div class="no-data" v-if="abnormalInfo.length < 1"> 暂无数据</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="water">
              <div class="water-box">
                <div class="water-top">
                  <div class="title-module12 title-module10">
                    <div class="width-title">水墨平衡测试报告</div>
                    <div class="width-top">INK BALANCE TEST REPORT</div>
                  </div>
                  <div class="style-shui">
                    <div v-if="formDate.name">
                      <div class="painting">
                        <div class="name">设备名称
                          <el-tooltip class="item" effect="dark" :content="formDate.name" placement="top-start">
                              <div>{{formDate.name}}</div>
                          </el-tooltip>
                        </div>
                        <div class="name">测评时间 <div>{{ formDate.date }}</div></div>
                      </div>
                      <div style="text-align: center">
                        <img v-if="formDate.result === 0" style="width: 130px" src="@/assets/img/cha.png" alt="">
                        <img v-if="formDate.result === 1" style="width: 130px" src="@/assets/img/zhong.png" alt="">
                        <img v-if="formDate.result === 2" style="width: 130px" src="@/assets/img/fine.png" alt="">
                        <img v-if="formDate.result === 3" style="width: 130px" src="@/assets/img/you.png" alt="">
                      </div>
                    </div>
                    <div v-else class="no-data">
                      暂无数据
                    </div>
                  </div>
                </div>
                <div class="water-bottom">
                  <div class="title-module15 title-module10">
                    <div class="width-title2">印刷保养完成情况</div>
                    <div class="width-top2">COMPLETION OF PRINTING MAINTENANCE</div>
                  </div>
                  <div style="background-color: rgba(255,255,255,0.4);box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-231">
                    <div style="height: 100%;overflow-y: auto;" class="aaa">
                      <div v-if="formDate.maintainList.length > 0">
                        <div class="device-box" v-for="(item ,index) in formDate.maintainList" :key="index">
                          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                            <div class="deviceTitle">
                              {{ item.name }}
                            </div>
                          </el-tooltip>
                          <div class="deviceCenter">
                            <div class="device-matter" style="background-color: rgba(235, 246, 254,1)">
                              <span>印刷</span>
                              <span>保养完成率</span>
                              <span>点检完成率</span>
                            </div>
                            <div class="device-matter device-matter3" v-for="(line,indexLine) in item.mainList" :key="indexLine">
                              <span>{{ line.type === 1 ? '日保' : line.type === 2 ? '周保' : line.type === 3 ? '双周保' : line.type === 4 ? '半月保' : line.type === 5 ? '月保' : line.type === 6 ? '季度保' : line.type === 7 ? '半年度' : '年度' }}</span>
                              <span style="color: #3576F2;">{{ line.value1 }}%</span>
                              <span style="color: #3576F2;">{{ line.value2 }}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="device-matter device-matter5 device-matter4 no-data-list" style="padding: 25px 0;text-align: center">
                        暂无数据
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="forward-box">
                <div class="forward-top">
                  <div class="title-module14 title-module10">
                    <div class="width-title3">异常待机提报</div>
                    <div class="width-top5">ABNORMAL STANDBY REPORT</div>
                  </div>
                  <div>
                    <div class="abnormal" style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                      <div class="device-matter device-matter1">
                        <span>异常提报</span>
                        <span>触发次数</span>
                        <span>填报次数</span>
                        <span>遗漏次数</span>
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.abnormalList.length > 0">
                            <div class="device-matter device-matter2" v-for="(item, index) in formDate.abnormalList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}</span>
                              <span style="color: #3576F2;">{{ item.value3 }}</span>
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="forward-bottom">
                  <div class="title-module title-module10">
                    <div>水箱报警</div>
                    <div>WATER TANK ALARM</div>
                  </div>
                  <div>
                    <div class="abnormal" style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                      <div class="device-matter device-matter4">
                        <span>水箱数据</span>
                        <span>异常触发</span>
                        <span>提报完成率</span>
<!--                        <span>审批完成率</span>-->
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.waterList.length > 0" >
                            <div class="device-matter device-matter5 device-matter4" v-for="(item, index) in formDate.waterList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}%</span>
<!--                              <span>{{ item.value3 }}%</span>-->
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pring">
              <div class="title-module13 title-module10">
                <div>水箱监控</div>
                <div class="width-top4">WATER TANK MONITORING</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-501">
                <div style="height: 100%;overflow-y: auto;" class="aaa">
                  <div>
                    <div v-for="(item, index) in formDate.shuiXiangList" :key="index" class="aaa">
                      <div v-if="item.valueList.length > 0">
                        <LineChart
                            style="padding-top: 15px"
                            v-if="item.chartData.source.length > 0"
                            :id="'deviceChartWaterTankShow2' + index"
                            ref="'deviceChartWaterTank'"
                            :data="item.chartData"
                            :linecolor="['#0acf97','#006df0','#fc7f03']"
                            :smooth="false"
                            :height="282"
                            :title="item.name"
                            :gridBottom="30"
                            :gridTop="30"
                            :gridLeft="50"
                            :show="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="formDate.shuiXiangList.length < 1" class="no-data no-data-line" style="">暂无数据</div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div :class="show === 3 ? 'active' : 'activeNone'">
          <div class="echarts-top" @mouseenter="handleMouse" @mouseleave="handleLeave">
            <div class="max-width-first">
              <div class="title-module11 title-module10">
                <div>印刷质量</div>
                <div>PRINTING QUALITY</div>
              </div>
              <div class="swiper-top" style="display: inline-block;background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;width: 100%;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <div class="swiper swiper-container" id="swiper_fa5">
                  <div class="swiper-wrapper swiper-wrapper2" >
                    <div class="swiper-slide" v-for="(item, index) in letterQuality" :key="index">
                      <RingChart2
                          style="padding: 10px 0"
                          v-if="item.chartData.length > 0"
                          :id="'chartShowSwiper3' + index"
                          :data="item.chartData"
                          :legendRight="'auto'"
                          :legendTop="'0%'"
                          :height="'300px'"
                          :totalTop="'52%'"
                          :legendFontSize="15"
                          :width="'335px'"
                          :title="item.name"
                          :titleTop="'bottom'"
                          :right="'center'"
                      ></RingChart2>
                    </div>
                  </div>
                  <div v-if="letterQuality.length < 1" class="no-data-top">暂无数据</div>
                  <!--            <div class="swiper-button-next"></div>-->
                  <!--            <div class="swiper-button-prev"></div>-->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>时间稼动率</div>
                <div>TIME OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="timeOeeList.length > 0"
                    :deviceName="deviceName"
                    :seriesData="seriesData"
                    :EchatsId="'EchatsIdShow3'"
                ></activation>
                <div v-if="timeOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
            <div style="margin-right: 0" class="max-width43-30">
              <div class="title-module13 title-module10">
                <div>性能稼动率</div>
                <div class="width-top6">PERFORMANCE OPERATION RATE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                <activation
                    v-if="capacityOeeList.length > 0"
                    :deviceName="deviceName2"
                    :seriesData="seriesData2"
                    :EchatsId="'EchatsId2Show3'"
                ></activation>
                <div v-if="capacityOeeList.length < 1" class="no-data-top2">暂无数据</div>
              </div>
            </div>
          </div>
          <div class="echarts-top">
            <div class="height-503">
              <div class="title-module12 title-module10">
                <div>异常待机原因</div>
                <div class="width-top3">ABNORMAL STANDBY CAUSE</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;height: 100%;overflow-y: auto;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="aaa">
                <div :style='`height: ${height3}px;`'>
                  <div id="carList3">
                    <div v-for="(item, index) in abnormalInfo" class="reason" :key="index" style="margin:  15px;border-bottom: 1px solid #C4DEFE">
                  <RingChart
                      v-if="item.chartData.length > 0"
                      :id="'chartShow3' + index"
                      :data="item.chartData"
                      :legendRight="'5%'"
                      :legendTop="'5%'"
                      :height="'250px'"
                      :center="['35%', '40%']"
                      :totalTop="'36%'"
                      :legendFontSize="15"
                      :legendOrient="'vertical'"
                      :width="'320px'"
                      :totalLeft="'-15%'"
                      :total="item.name"
                  ></RingChart>
                </div>
                    <div class="no-data" v-if="abnormalInfo.length < 1"> 暂无数据</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="water">
              <div class="water-box">
                <div class="water-top">
                  <div class="title-module12 title-module10">
                    <div class="width-title">水墨平衡测试报告</div>
                    <div class="width-top">INK BALANCE TEST REPORT</div>
                  </div>
                  <div class="style-shui">
                    <div v-if="formDate.name">
                      <div class="painting">
                        <div class="name">设备名称
                          <el-tooltip class="item" effect="dark" :content="formDate.name" placement="top-start">
                            <div>{{formDate.name}}</div>
                          </el-tooltip>
                        </div>
                        <div class="name">测评时间 <div>{{ formDate.date }}</div></div>
                      </div>
                      <div style="text-align: center">
                        <img v-if="formDate.result === 0" style="width: 130px" src="@/assets/img/cha.png" alt="">
                        <img v-if="formDate.result === 1" style="width: 130px" src="@/assets/img/zhong.png" alt="">
                        <img v-if="formDate.result === 2" style="width: 130px" src="@/assets/img/fine.png" alt="">
                        <img v-if="formDate.result === 3" style="width: 130px" src="@/assets/img/you.png" alt="">
                      </div>
                    </div>
                    <div v-else class="no-data">
                      暂无数据
                    </div>
                  </div>
                </div>
                <div class="water-bottom">
                  <div class="title-module15 title-module10">
                    <div class="width-title2">印刷保养完成情况</div>
                    <div class="width-top2">COMPLETION OF PRINTING MAINTENANCE</div>
                  </div>
                  <div style="background-color: rgba(255,255,255,0.4);box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-231">
                    <div style="height: 100%;overflow-y: auto;" class="aaa">
                      <div v-if="formDate.maintainList.length > 0">
                        <div class="device-box" v-for="(item ,index) in formDate.maintainList" :key="index">
                          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                            <div class="deviceTitle">
                              {{item.name}}
                            </div>
                          </el-tooltip>
                          <div class="deviceCenter">
                            <div class="device-matter" style="background-color: rgba(235, 246, 254,1)">
                              <span>印刷</span>
                              <span>保养完成率</span>
                              <span>点检完成率</span>
                            </div>
                            <div class="device-matter device-matter3" v-for="(line,indexLine) in item.mainList" :key="indexLine">
                              <span>{{ line.type === 1 ? '日保' : line.type === 2 ? '周保' : line.type === 3 ? '双周保' : line.type === 4 ? '半月保' : line.type === 5 ? '月保' : line.type === 6 ? '季度保' : line.type === 7 ? '半年度' : '年度' }}</span>
                              <span style="color: #3576F2;">{{ line.value1 }}%</span>
                              <span style="color: #3576F2;">{{ line.value2 }}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="device-matter device-matter5 device-matter4 no-data-list" style="padding: 25px 0;text-align: center">
                        暂无数据
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="forward-box">
                <div class="forward-top">
                  <div class="title-module14 title-module10">
                    <div class="width-title3">异常待机提报</div>
                    <div class="width-top5">ABNORMAL STANDBY REPORT</div>
                  </div>
                  <div>
                    <div class="abnormal" style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                      <div class="device-matter device-matter1">
                        <span>异常提报</span>
                        <span>触发次数</span>
                        <span>填报次数</span>
                        <span>遗漏次数</span>
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.abnormalList.length > 0" id="abnormalList3">
                            <div class="device-matter device-matter2" v-for="(item, index) in formDate.abnormalList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}</span>
                              <span style="color: #3576F2;">{{ item.value3 }}</span>
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="forward-bottom">
                  <div class="title-module title-module10">
                    <div>水箱报警</div>
                    <div>WATER TANK ALARM</div>
                  </div>
                  <div>
                    <div class="abnormal" style="box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);">
                      <div class="device-matter device-matter4">
                        <span>水箱数据</span>
                        <span>异常触发</span>
                        <span>提报完成率</span>
<!--                        <span>审批完成率</span>-->
                      </div>
                      <div class="height-56">
                        <div style="height: 100%;overflow-y: auto;" class="aaa">
                          <div v-if="formDate.waterList.length > 0" >
                            <div class="device-matter device-matter5 device-matter4" v-for="(item, index) in formDate.waterList" :key="index">
                              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                                <span>{{ item.name }}</span>
                              </el-tooltip>
                              <span style="color: #3576F2;">{{ item.value1 }}</span>
                              <span style="color: #3576F2;">{{ item.value2 }}%</span>
<!--                              <span>{{ item.value3 }}%</span>-->
                            </div>
                          </div>
                          <div v-else class="device-matter device-matter5 device-matter4" style="background-color: #fff;font-size: 24px;padding: 25px 0;text-align: center">
                            暂无数据
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pring">
              <div class="title-module13 title-module10">
                <div>水箱监控</div>
                <div class="width-top4">WATER TANK MONITORING</div>
              </div>
              <div style="background-color: rgba(255,255,255,0.4);border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);" class="height-501">
                <div style="height: 100%;overflow-y: auto;" class="aaa">
                  <div>
                    <div v-for="(item, index) in formDate.shuiXiangList" :key="index" class="aaa">
                      <div v-if="item.valueList.length > 0">
                        <LineChart
                            style="padding-top: 15px"
                            v-if="item.chartData.source.length > 0"
                            :id="'deviceChartWaterTankShow3' + index"
                            ref="'deviceChartWaterTank'"
                            :data="item.chartData"
                            :linecolor="['#0acf97','#006df0','#fc7f03']"
                            :smooth="false"
                            :height="282"
                            :title="item.name"
                            :gridBottom="30"
                            :gridTop="30"
                            :gridLeft="50"
                            :show="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="formDate.shuiXiangList.length < 1" class="no-data no-data-line" style="">暂无数据</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RingChart2 from '@/components/Charts/RingChart2'; // 饼图组件
import RingChart from '@/components/Charts/RingChart'; // 饼图组件
import activation from '@/components/Charts/activation'; //
import LineChart from '@/components/Charts/LineChart'; //
import Swiper from 'swiper';
import screenfull from "screenfull";
import API from '@/api';
import cookies from "@/utils/cookies";
import {store} from "@/store";
import {isEmail} from "@/utils/validate";
// import 'swiper/dist/css/swiper.min.css';
// import LineChartWater from '@/components/Charts/LineChartWater'; //多个y轴线型图
export default {
  name: "boss",
  components: { RingChart2, activation, RingChart, LineChart },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.param.newPassword1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      iconImg: false,
      height: '',
      height2: '',
      height3: '',
      heightAbnormalList: '',
      width: '',
      fullScreenTrue: false,
      isFullscreen: true,
      nowDate:'',
      nowDateList: '',
      formDate: {
        abnormalList: [],
        waterList: [],
        shuiXiangList: [],
    maintainList: []
      },
      timeOeeList: [], // 时间稼动率
      letterQuality: [],// 印刷质量
      abnormalInfo: [], // 异常待机原因
      capacityOeeList:[],
      deviceName: [], //时间稼动率设备名称
      seriesData: [], // 时间稼动率设备数据
      deviceName2: [], //性能稼动率设备名称
      seriesData2: [], // 性能稼动率设备数据
      type: 1,
      show: 1,
      // 柱形颜色
      barColor: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FA460D' },
          { offset: 1, color: '#F7B515' },
        ]),
      ],
      defaultUrl: '',
      data: '', // 个人资料
      fileList: [], // 上传的文件列表
      listdata: new FormData(),
      phone: '',
      NewParam: {
        phone: '',
        code: '', // 新手机号验证码
      },
      AuthCode: true, // 控制倒计时显示
      auth_time: 60, // 验证码倒计时
      AuthCode2: true, // 新手机号控制显示
      auth_time2: 60, // 新手机号倒计时
      oldCode: {
        code: '', // 原手机号验证码
      },
      loading: false, // 加载
      dialogNewPhone: false,
      dialog: {
        chack: false,
        edit: false,
        phone: false,
        password: false,
        info: false,
        title: {
          chack: '查看用户资料',
          edit: '编辑用户资料',
          phone: '编辑手机号',
          password: '编辑密码',
          info: '系统消息',
        },
        status: '',
      },
      // 密码
      param: {
        password: '',
        newPassword1: '',
        newPassword2: '',
      },
      change: {
        name: '',
        email: '',
        phone: '',
        code: '', // 新手机号验证码
      },
      infoData: [],
      info: {
        count: 10,
        page: 0,
        total: 0,
        type: '',
      },
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      passwordType: 'password',
      passwordType2: 'password',
      passwordType3: 'password',
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE,
      VUE_APP_HOME: process.env.VUE_APP_LOGIN,
      VUE_APP_LOGIN: process.env.VUE_APP_LOGIN,
      router: '',
      rules: {
        name: [{ required: true, trigger: 'change', message: '请输入账号昵称' }],
        code: [
          { required: true, trigger: 'change', message: '请输入验证码' },
          {
            pattern: /^\d{6}$/,
            message: '请输入验证码的正确格式',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, trigger: 'change', message: '请输入邮箱' },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入邮箱的正确格式',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    // 上传的地址
    Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },
  },
  created() {
    this.currentTime()
    this.getList()
    this.getUserInfo();
  },
  mounted() {
    setTimeout(() => {
      new Swiper('#swiper_fa3', {
        loop: true,
        autoplay: {delay: 1000,stopOnLastSlide: false,disableOnInteraction: true,},
        updateOnImagesReady: true,
        slidesPerView: 2,
        spaceBetween: 30,
        observer:true,
        observeParents: true,
        observeSlideChildren: true,
        // slidesPerGroup: 2,
        // loopFillGroupWithBlank: true,
        // centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationClickable:true,
        },

        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // }
      });
    })

    new Swiper('#swiper_fa4', {
      loop: true,
      autoplay: {delay: 1000,stopOnLastSlide: false,disableOnInteraction: true,},
      updateOnImagesReady: true,
      slidesPerView: 2,
      spaceBetween: 30,
      observer:true,
      observeParents: true,
      observeSlideChildren: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    new Swiper('#swiper_fa5', {
      loop: true,
      autoplay: {delay: 1000,stopOnLastSlide: false,disableOnInteraction: true,},
      updateOnImagesReady: true,
      slidesPerView: 2,
      spaceBetween: 30,
      observer:true,
      observeParents: true,
      observeSlideChildren: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    // const marquee = document.getElementById('swiper_fa31');
    // marquee.innerHTML = marquee.innerHTML + marquee.innerHTML;
    // // console.log(marquee.scrollHeight / 2 )
    // this.width = marquee.scrollWidth / 2
    // console.log(this.width)
  },
  methods: {
    getList() {
      this.height = ''
      this.height2 = ''
      this.height3 = ''
      API.DaPingCheJian({type: this.type}).then(res => {
        console.log(res)
        if (res.message.success) {
          this.abnormalInfo = []
          this.formDate = res.message.data
          this.letterQuality = res.message.data.printList
          this.timeOeeList = res.message.data.timeOeeList
          this.capacityOeeList = res.message.data.capacityOeeList
          this.abnormalInfo = res.message.data.abnormalInfo
          // list = []
          this.letterQuality.forEach(item => {
            let data = []
            // if ((item.value1 === 0) && (item.value2 === 0) && (item.value3 === 0) && (item.value4 === 0)) {
            //   data = []
            // } else {
              data = [
                { value: item.value1, name: '0-60' },
                { value: item.value2, name: '60-70' },
                { value: item.value3, name: '70-80' },
                { value: item.value4, name: '80-100' },
              ]
            // }
            item.chartData = data
          })
          this.deviceName = []
          this.seriesData = []
          this.timeOeeList.map(item => {
            this.deviceName.push(item.time);
            this.seriesData.push({
              type: "bar",
                data: [parseFloat(item.value)],
              name: item.time,
              coordinateSystem: "polar",
              label: {
                show: false,
                position: "middle",
                formatter: "{c}",
              },
              barGap: "150%",
              barMaxWidth: "10%",
            });
          })
          console.log(this.seriesData)
          this.deviceName2 = []
          this.seriesData2 = []
          this.capacityOeeList.map(item => {
            this.deviceName2.push(item.time);
            this.seriesData2.push({
              type: "bar",
              data: [parseFloat(item.value)],
              name: item.time,
              coordinateSystem: "polar",
              label: {
                show: false,
                position: "middle",
                formatter: "{c}",
              },
              barGap: "150%",
              barMaxWidth: "10%",
            });
          })
          // let data = []
          for (let i = 0; i < this.abnormalInfo.length; i ++) {
            this.abnormalInfo[i].chartData = []
            for (let j = 0; j < this.abnormalInfo[i].valueList.length; j++) {
              this.abnormalInfo[i].chartData.push(
                    { value: this.abnormalInfo[i].valueList[j].value, name: this.abnormalInfo[i].valueList[j].time },
                  )
            }
          }
          for (let i = 0; i < this.formDate.shuiXiangList.length; i ++) {
            this.formDate.shuiXiangList[i].chartData = {
              // 数据集
              source: [],
              // y轴
              yAxis: [
                {
                  type: 'value',
                  splitLine: { show: true },
                  position: 'left',
                  offset: 70,
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: '#0acf97',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: { show: false },
                  position: 'left',
                  offset: 30,
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: '#006df0',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
                {
                  type: 'value',
                  splitLine: { show: false }, // x轴网格线
                  position: 'left',
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: '#fc7f03',
                    },
                  },
                  axisLabel: {
                    formatter: '{value}',
                  },
                },
              ],
              // 系列
              series: [
                { type: 'line', yAxisIndex: 0 },
                { type: 'line', yAxisIndex: 1 },
                { type: 'line', yAxisIndex: 2 },
              ],
            }
            this.formDate.shuiXiangList[i].WaterTank = []; // 水箱
            for (let j = 0; j < this.formDate.shuiXiangList[i].valueList.length; j++) {
              this.formDate.shuiXiangList[i].WaterTank.push(
                  [
                    this.formDate.shuiXiangList[i].valueList[j].time,
                    this.formDate.shuiXiangList[i].valueList[j].sw, // PH值
                    this.formDate.shuiXiangList[i].valueList[j].ddl, // 电导率
                    this.formDate.shuiXiangList[i].valueList[j].ph, // 水温
                  ]);
              this.formDate.shuiXiangList[i].chartData.source = [
                ['product', '温度', '电导率', 'PH值'],
                ...this.formDate.shuiXiangList[i].WaterTank,
              ];
            }
          }

        }
      })
      // this.width = ''
      // const marquee = document.getElementById('swiper_fa31');
      // marquee.innerHTML = marquee.innerHTML + marquee.innerHTML;
      // // console.log(marquee.scrollHeight / 2 )
      // this.width = marquee.scrollWidth / 2
      // console.log(this.width)
      // const marquee2 = document.getElementById('carList2');
      // marquee2.innerHTML = marquee2.innerHTML + marquee2.innerHTML;
      // console.log(marquee2.scrollHeight / 2 )
      // this.height2 = marquee2.scrollHeight / 2
      // const marquee3 = document.getElementById('carList3');
      // marquee3.innerHTML = marquee3.innerHTML + marquee3.innerHTML;
      // console.log(marquee3.scrollHeight / 2 )
      // this.height2 = marquee3.scrollHeight / 2
    },
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${hour}:${minute}:${second}`;
      this.nowDateList = `${year}.${month}.${day}`;
      this.nowDateWeek = `${weekArr[week]}`
    },
    handleDay(val) {
      this.show = val
      if (val === 1) {
        this.type = 1
        // const marquee = document.getElementById('carList');
        // marquee.innerHTML = marquee.innerHTML + marquee.innerHTML;
        // console.log(marquee.scrollHeight / 2 )
        // this.height = marquee.scrollHeight / 2
        this.getList()
      }
      if (val === 2) {
        this.type = 2
        // if (cookies.get('teamId') === '128812f8-354f-470f-b8fa-c33ff62b51d1') {
        //   this.letterQuality = [
        //     {
        //       "name": "印刷机3号",
        //       "value1": 0,
        //       "value2": 0,
        //       "value3": 10,
        //       "value4": 90
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "value1": 0,
        //       "value2": 0,
        //       "value3": 20,
        //       "value4": 80
        //     }
        //   ]
        //   this.letterQuality.forEach(item => {
        //     let data = []
        //     // if ((item.value1 === 0) && (item.value2 === 0) && (item.value3 === 0) && (item.value4 === 0)) {
        //     //   data = []
        //     // } else {
        //     data = [
        //       { value: item.value1, name: '0-60' },
        //       { value: item.value2, name: '60-70' },
        //       { value: item.value3, name: '70-80' },
        //       { value: item.value4, name: '80-100' },
        //     ]
        //     // }
        //     item.chartData = data
        //   })
        //   this.deviceName = []
        //   this.seriesData = []
        //   this.timeOeeList = [
        //     {
        //       "date": null,
        //       "time": "印刷机1号",
        //       "value": "32.5",
        //       "data": null
        //     },
        //     {
        //       "date": null,
        //       "time": "印刷机3号",
        //       "value": "30.5",
        //       "data": null
        //     },
        //   ]
        //   this.timeOeeList.map(item => {
        //     this.deviceName.push(item.time);
        //     this.seriesData.push({
        //       type: "bar",
        //       data: [parseFloat(item.value)],
        //       name: item.time,
        //       coordinateSystem: "polar",
        //       label: {
        //         show: false,
        //         position: "middle",
        //         formatter: "{c}%",
        //       },
        //       barGap: "150%",
        //       barMaxWidth: "10%",
        //     });
        //   })
        //   this.capacityOeeList = [
        //     {
        //       "date": null,
        //       "time": "印刷机1号",
        //       "value": "76.35",
        //       "data": null
        //     },
        //     {
        //       "date": null,
        //       "time": "印刷机3号",
        //       "value": "73.37",
        //       "data": null
        //     },
        //   ]
        //   this.deviceName2 = []
        //   this.seriesData2 = []
        //   this.capacityOeeList.map(item => {
        //     this.deviceName2.push(item.time);
        //     this.seriesData2.push({
        //       type: "bar",
        //       data: [parseFloat(item.value)],
        //       name: item.time,
        //       coordinateSystem: "polar",
        //       label: {
        //         show: false,
        //         position: "middle",
        //         formatter: "{c}%",
        //       },
        //       barGap: "150%",
        //       barMaxWidth: "10%",
        //     });
        //   })
        //   this.abnormalInfo = [
        //     {
        //       "name": "印刷机3号",
        //       "valueList": [
        //         {
        //           "date": null,
        //           "time": "试印校色",
        //           "value": "4",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "签样等待",
        //           "value": "1",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "落版改文件",
        //           "value": "5",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "调专色等待",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "材料原因",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "故障抢修",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "等料",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "其他原因",
        //           "value": "0",
        //           "data": null
        //         }
        //       ]
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "valueList": [
        //         {
        //           "date": null,
        //           "time": "试印校色",
        //           "value": "4",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "签样等待",
        //           "value": "2",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "落版改文件",
        //           "value": "4",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "调专色等待",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "材料原因",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "故障抢修",
        //           "value": "0",
        //           "data": null
        //         },
        //         {
        //           "date": null,
        //           "time": "等料",
        //           "value": "0",
        //           "data": null
        //         },印刷保养完成情况
        //         {
        //           "date": null,
        //           "time": "其他原因",
        //           "value": "0",
        //           "data": null
        //         }
        //       ]
        //     }
        //   ]
        //   for (let i = 0; i < this.abnormalInfo.length; i ++) {
        //     this.abnormalInfo[i].chartData = []
        //     for (let j = 0; j < this.abnormalInfo[i].valueList.length; j++) {
        //       this.abnormalInfo[i].chartData.push(
        //           { value: this.abnormalInfo[i].valueList[j].value, name: this.abnormalInfo[i].valueList[j].time },
        //       )
        //     }
        //   }
        //   this.formDate.shuiXiangList = [
        //     {
        //       "name": "印刷机3号",
        //       "valueList": [
        //         {
        //           "time": "2023-10-30 08:05:02",
        //           "ph": 4.812,
        //           "sw": 12.1,
        //           "ddl": 2197
        //         },
        //         {
        //           "time": "2023-10-30 12:05:01",
        //           "ph": 4.786,
        //           "sw": 8.9,
        //           "ddl": 2256
        //         },
        //         {
        //           "time": "2023-10-30 16:05:02",
        //           "ph": 4.742,
        //           "sw": 9,
        //           "ddl": 2307
        //         },
        //       ]
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "valueList": [
        //         {
        //           "time": "2023-10-30 08:05:02",
        //           "ph": 5.871,
        //           "sw": 21.7,
        //           "ddl": 804
        //         },
        //         {
        //           "time": "2023-10-30 12:05:01",
        //           "ph": 5.766,
        //           "sw": 8.8,
        //           "ddl": 856
        //         },
        //         {
        //           "time": "2023-10-30 16:05:02",
        //           "ph": 5.793,
        //           "sw": 9.4,
        //           "ddl": 838
        //         },
        //       ]
        //     }
        //   ]
        //   for (let i = 0; i < this.formDate.shuiXiangList.length; i ++) {
        //     this.formDate.shuiXiangList[i].chartData = {
        //       // 数据集
        //       source: [],
        //       // y轴
        //       yAxis: [
        //         {
        //           type: 'value',
        //           splitLine: { show: true },
        //           position: 'left',
        //           offset: 70,
        //           axisLine: {
        //             show: true,
        //             lineStyle: {
        //               color: '#0acf97',
        //             },
        //           },
        //           axisLabel: {
        //             formatter: '{value}',
        //           },
        //         },
        //         {
        //           type: 'value',
        //           splitLine: { show: false },
        //           position: 'left',
        //           offset: 30,
        //           axisLine: {
        //             show: true,
        //             lineStyle: {
        //               color: '#006df0',
        //             },
        //           },
        //           axisLabel: {
        //             formatter: '{value}',
        //           },
        //         },
        //         {
        //           type: 'value',
        //           splitLine: { show: false }, // x轴网格线
        //           position: 'left',
        //           axisLine: {
        //             show: true,
        //             lineStyle: {
        //               color: '#fc7f03',
        //             },
        //           },
        //           axisLabel: {
        //             formatter: '{value}',
        //           },
        //         },
        //       ],
        //       // 系列
        //       series: [
        //         { type: 'line', yAxisIndex: 0 },
        //         { type: 'line', yAxisIndex: 1 },
        //         { type: 'line', yAxisIndex: 2 },
        //       ],
        //     }
        //     this.formDate.shuiXiangList[i].WaterTank = []; // 水箱
        //     for (let j = 0; j < this.formDate.shuiXiangList[i].valueList.length; j++) {
        //       this.formDate.shuiXiangList[i].WaterTank.push(
        //           [
        //             this.formDate.shuiXiangList[i].valueList[j].time,
        //             this.formDate.shuiXiangList[i].valueList[j].sw, // PH值
        //             this.formDate.shuiXiangList[i].valueList[j].ddl, // 电导率
        //             this.formDate.shuiXiangList[i].valueList[j].ph, // 水温
        //           ]);
        //       this.formDate.shuiXiangList[i].chartData.source = [
        //         ['product', '温度', '电导率', 'PH值'],
        //         ...this.formDate.shuiXiangList[i].WaterTank,
        //       ];
        //     }
        //   }
        //   this.formDate.maintainList = [
        //     {
        //       "name": "印刷机3号",
        //       "mainList": [
        //         {
        //           "type": 2,
        //           "value1": "80",
        //           "value2": "0"
        //         }
        //       ]
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "mainList": [
        //         {
        //           "type": 2,
        //           "value1": "100",
        //           "value2": "100"
        //         }
        //       ]
        //     }
        //   ]
        //   this.formDate.waterList = [
        //     {
        //       "name": "印刷机3号",
        //       "value1": "0",
        //       "value2": "0",
        //       "value3": null
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "value1": "0",
        //       "value2": "0",
        //       "value3": null
        //     }
        //   ]
        //   this.formDate.abnormalList =[
        //     {
        //       "name": "印刷机3号",
        //       "value1": "10",
        //       "value2": "4",
        //       "value3": "6"
        //     },
        //     {
        //       "name": "印刷机1号",
        //       "value1": "10",
        //       "value2": "5",
        //       "value3": "5"
        //     }
        //   ]
        //   this.formDate.result = 2
        //   this.formDate.name = '曼罗兰1420'
        //   this.formDate.date = '2023-10-30 11:32:41'
        // } else {
          this.getList()
        // }

        // const marquee2 = document.getElementById('carList2');
        // marquee2.innerHTML = marquee2.innerHTML + marquee2.innerHTML;
        // console.log(marquee2.scrollHeight / 2 )
        // this.height2 = marquee2.scrollHeight / 2
        }
      if (val === 3) {
        this.type = 3
        this.getList()
        // const marquee3 = document.getElementById('carList3');
        // marquee3.innerHTML = marquee3.innerHTML + marquee3.innerHTML;
        // console.log(marquee3.scrollHeight / 2 )
        // this.height2 = marquee3.scrollHeight / 2
      }
      // this.getList()
    },
    currentTime(){
      setInterval(()=>{
        this.getDate()
      },1000)
    },
    handleMouse(event) {
      // this.fullScreenTrue = true
      // console.log(event.clientX,event.clientY)
      if (event.clientY < 530){
        this.fullScreenTrue = true
      }
    },
    handleMousemove(event) {
      console.log(event.clientX,event.clientY)
      // this.x = event.clientX;
      // this.y = event.clientY;
    },
    handleLeave() {
      this.fullScreenTrue = false
    },
    click() {
      if (this.isFullscreen) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
      let element = document.getElementById("box999"); //指定全屏区域元素
      screenfull.toggle(element); //全屏显示
    },
    handleIcon() {
      if (this.iconImg === true) {
        this.iconImg = false
      } else {
        this.iconImg = true
      }
    },
    handleIcon1() {
      if (this.iconImg === true) {
        this.iconImg = false
      }
    },
    // 返回portal首页
    ToHomepage() {
      // window.open(`${this.VUE_APP_HOME}`);
    },
    // 退出登录
    exit() {
      this.$confirm('确定要退出此用户?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            store.dispatch('logout').then(() => {
              cookies.remove('token');
              window.open(`${this.VUE_APP_HOME}login/:SUP/`, '_self');
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // });
          });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        console.log(res)
        if (res.message.success) {
          this.userInfo = res.message.data;
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
* {
  //background-color: #fff;
  //
}
.max-width-first {
  width: 43%;
  margin-right: 15px
}
.max-width43-30 {
  width: calc((100% - 43% - 30px) / 2);margin-right: 15px
}
.max-width301 {
  display: none;
}
.max-width302 {
  display: block;
  width: 100%;
}
.max-width43 {
  width: 100%;
}
.max-width29 {
  width: 100%;
}
.box {
  position: relative;
  background-image: url("~@/assets/img/backImg.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 40px 40px;
}
.title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  //border-bottom: 1px solid #dcdddd;
  color: #fff;
  line-height: 61px;
}
.time {
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  align-items: end;
  font-size: 20px;
  color: #3173F1;
}
.timeYear {
  position: absolute;
  right: 130px;
  top: 40px;
  display: flex;
  align-items: end;
  font-size: 20px;
  color: #3173F1;
}
.boss {
  padding-top: 40px;
}
.selected-time {
  position: absolute;
  left: 40px;
  top: 35px;
  display: flex;
  align-items: end;
}
.selected-time li {
  border: 1px solid #8BB3FF;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 2px 2px 5px 0px #a0c3ee;
  padding: 13px 45px;
  background-color: #ecf7ff;
  margin-right: 15px;
  color: #3173F1;
  font-size: 16px;
}
li{
  display: inline-block;
  list-style: none;
  cursor: pointer;
  padding: 2px 5px;
}
.title-module11 {
  background-image: url("~@/assets/img/backTitle2.png");
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module12 {
  background-image: url("~@/assets/img/8.png");
  //25
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module13 {
  background-image: url("~@/assets/img/11.png");
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module14 {
  background-image: url("~@/assets/img/9.png");
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module15 {
  background-image: url("~@/assets/img/13.png");
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module {
  background-image: url("~@/assets/img/12.png");
  background-size: cover;
  background-position: center;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.title-module10 div:first-child {
  margin-left: 16px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
}
.title-module10 div:last-child {
  color: #3173F1;
  font-size: 12px;
}
.echarts-top {
  display: flex;
  margin-bottom: 15px;
}
.echarts-top:last-child{
  margin-bottom: 0;
}
.water {
  width: calc(100% - 23% - 27.6% - 10px);margin-right: 15px
}
.water-top {
  display: inline-block;margin-right: 15px;width: 45%;
}
.water-box {
  display: inline-flex;width: 100%;
}
.water-bottom {
  display: inline-block;margin-bottom: 15px;width: calc(100% - 45%);
}
.forward-box {
  display: flex;
}
.forward-top{
  display: inline-block; margin-right: 15px;width: 50%;
}
.forward-bottom{
  display: inline-block;width: calc(50% - 15px);
}
.pring {
  width: 27.6%
}
.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;;
  width: 20px;
  height: 20px;
  vertical-align: 10px;
}
.swiper-container {
  overflow: hidden;
}
.swiper-slide {
  width: 335px!important;
  display: inline-block;
}
.swiper-wrapper {
  width: 100%;
  display: inline-flex;

}
.swiper-container {
  width: 100%;;
}
.swiper-wrapper2 {
  //display: inline-flex;
  //animation: swiperrun 100s linear infinite;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next1';
}
.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 20%;
  width: calc(var(--swiper-navigation-size)/ 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color,var(--swiper-theme-color));
}
//.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
//  content: 'prev';
//}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-prev {
  left: 10px;
  right: auto;
}
.swiper-button-next {
  right: 10px;
  left: 10px;
  background-color: rgb(255,255,255,0);
}
.fullScreen {
  display: none;
}
.active {
  display: block;
}
.active2 {
  display: block;
}
.activeNone {
  display: none;
}
.painting {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}
.painting .name {
  display: inline-block;
}
.painting .name:first-child {
  //width: 150px;
  //overflow: hidden;
}
.painting .name:first-child div {
  width: 150px;
  overflow: hidden; white-space: nowrap;
}
.device-box {
  display: flex;
  border-left: 1px solid #8BB3FF;
  border-right: 1px solid #8BB3FF;
  border-top: 1px solid #8BB3FF;
  border-bottom: 0px solid #8BB3FF;
}
.device-box:last-child {
  border-bottom: 1px solid #8BB3FF;
}
.deviceTitle {
  width: 25%;
  font-size: 20px;
  text-align: center;
  //line-height: 114px;
  background-color: rgba(255,255,255,0);
  overflow: hidden;
  white-space: nowrap;
  //text-overflow: ellipsis; white-space: nowrap;
}
.deviceCenter {
  width: 80%;
}
.device-matter span {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  padding: 3px 0;
  border-left: 1px solid #8BB3FF;
  border-bottom: 1px solid #8BB3FF;
  height: 38px;
  line-height: 32px;
}
.device-matter span:nth-child(1){
  width: 26%;
}
.device-matter span:nth-child(2){
  width: 37%;
}
.device-matter span:nth-child(3){
  width: 37%;
}
.device-matter:last-child span {
  border-bottom: 0
}
.abnormal .device-matter span{
  width: 25%;
}
.abnormal .device-matter:first-child span {
  border-top: 1px solid #8BB3FF;
}
.abnormal .device-matter:last-child span {
  border-bottom: 1px solid #8BB3FF;
}
.abnormal .device-matter span:last-child{
  border-right: 1px solid #8BB3FF;
}
.device-matter1 span {
  line-height: 55px;
  height: 60px;
}
.abnormal .device-matter4 span{
  width: 33.3%;
}
//.abnormal .device-matter4 span:nth-child(3){
//  width: 27%;
//}
//.abnormal .device-matter4 span:nth-child(4){
//  width: 27%;
//}
.device-matter4 span {
  line-height: 55px;
  height: 60px;
}
.device-matter2 {
  width: 100%;
  line-height: 55px;
  height: 60px;
}

.device-matter2 span{
  background-color: rgba(255,255,255,0.6);
  line-height: 55px;
  height: 60px;
  //width: 25.3% !important;
  overflow: hidden;
  //text-overflow: ellipsis; white-space: nowrap;
}
.device-matter5 {
  width: 100%;
  line-height: 55px;
  height: 60px;
}

.device-matter5 span{
  background-color: rgba(255,255,255,0.6);
  line-height: 55px;
  height: 60px;
  //width: 25.3% !important;
  overflow: hidden;
  //text-overflow: ellipsis; white-space: nowrap;
}
.device-matter3 span{
  background-color: rgba(255,255,255,0);
}

.aaa::-webkit-scrollbar { width: 0 !important }
/*IE 10+*/
.aaa { -ms-overflow-style: none; }
/*Firefox*/
.aaa { overflow: -moz-scrollbars-none; }
.no-data {
  font-size: 24px;
  text-align: center;
  line-height: 200px;
}
.no-data-line {
  line-height: 505px;
}
.no-data-list {
  font-size: 24px;
}
.no-data-top {
  font-size: 24px;
  text-align: center;
  line-height: 265px;
}
.no-data-top2 {
  font-size: 24px;
  text-align: center;
  line-height: 265px;
}
.deviceTitle::before{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 100%;
  content: '';
}
.tionY {
  //height: 3000px;
  //transition: all 0.5s;
  animation: marquee 40s linear infinite
}
.tionY2 {
  //height: 3000px;
  //transition: all 0.5s;
  animation: marquee2 10s linear infinite
}
.marquee {
  //animation-delay: -5s;
  animation: marquee 100s linear infinite;
}
.marquee2 {
  //animation-delay: -5s;
  animation: marquee2 100s linear infinite;
}
//.marquee:hover {
//  animation-play-state: paused;
//}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translateY(-70%); //这里不是-100%！
    //transform: translate3d(0, -2201px, 0)
  }
}
@keyframes marquee2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translateY(-57%); //这里不是-100%！
    //transform: translate3d(0, -2201px, 0)
  }
}
@keyframes swiperrun {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translateX(-90%); //这里不是-100%！
    //transform: translate3d(0, -2201px, 0)
  }
}
.width-title {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-title2 {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-title3 {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-top {
  width: 167px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-top2 {
  width: 251px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-top3 {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
}
.width-top4 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.width-top5 {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.width-top6 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.height-503 {
  height: 505px;
  width: 22%;margin-right: 15px;
}
.height-501 {
  height: 505px;
}
.height-231 {
  height: 232px;
}
.style-shui {
  background-color: rgba(255,255,255,0.4);padding: 15px;border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);
}
.height-56 {
  height: 156px;
}
.header-box {
  display: none;
}
@media screen and (max-width:800px) {
  .header-box {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 99999;
    .menu-app {
      display: none;
      position: absolute;
      bottom: -750px;
      left: 0;
      background-color: #fff;
      z-index: 9999;
      width: 100%;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: 0rem 0px 18px 0px #aaa6a6;
      ul{
        list-style: none;
        padding: 0 100px;
        li {
          display: block;
          border-bottom: 1px solid #E1E1E1;
          font-size: 78px;
          color: #000;
          padding: 70px 0;
          text-align: center;
        }
        img {
          width: 70px;
          margin-right: 20px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: inline-block;
          width: 600px;
          text-align: left;
        }
      }
    }
    .activeImg {
      display: block;
    }
    .right-box {
      float: right;
      //margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      //height: 62px;
      //width: 202px;
      text-align: center;
      .img {
        margin-top: 30px;
        //height: 41px;
        width: 300px;
      }
    }
    .head li {
      font-size: 88px;
      color: #fff !important;
      //width: 80px;
      //height: 62px;
      //line-height: 62px;
      position: relative;
      align-items: center;
      float: left;
      //font-weight: bold;
      // margin-right: 80px;
      cursor: pointer;
      display: flex;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .icon-order {
      width: 100px;
      height: 70px;
      margin-left: 30px;
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .head {
      .active {
        color: #fff;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
  .max-width-first {
    width: 100%;
  }
  .max-width43-30 {
    width: 100%;
  }
  .box {
    position: relative;
    background-image: url("~@/assets/img/backBack.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
  }
  .title {
    font-size: 110px;
    font-weight: bold;
    text-align: center;
    //border-bottom: 1px solid #dcdddd;
    color: #fff;
    line-height: 270px;
    background-image: url("~@/assets/img/bcak-title.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
  }
  .time {
    position: relative;
    left: 100px;
    top: 20px;
    display: none;
    align-items: end;
    font-size: 88px;
    color: #3173F1;
  }
  .timeYear {
    position: relative;
    left: 80px;
    top: 20px;
    display: none;
    align-items: end;
    font-size: 88px;
    color: #3173F1;
  }
  .boss {
    padding: 100px 40px 40px;
    margin-top: 300px;
  }
  .selected-time {
    position: relative;
    left: 40px;
    top: 350px;
    display: inline-block;
    align-items: end;
  }
  .selected-time li {
    border: 1px solid #8BB3FF;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 2px 2px 5px 0px #a0c3ee;
    padding: 30px 160px;
    background-color: #ecf7ff;
    margin-right: 50px;
    color: #3173F1;
    font-size: 88px;
  }
  li{
    display: inline-block;
    list-style: none;
    cursor: pointer;
    padding: 2px 5px;
  }
  .title-module11 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module12 {
    background-image: url("~@/assets/img/8.png");
    //25
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module13 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module14 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module15 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
  .title-module10 div:first-child {
    margin-left: 50px;
    color: #333;
    font-weight: bold;
    font-size: 74px;
  }
  .title-module10 div:last-child {
    color: #3173F1;
    font-size: 50px;
    //display: none;
  }
  .echarts-top {
    display: block;
    margin-bottom: 15px;
  }
  .echarts-top:last-child{
    margin-bottom: 0;
  }
  .water {
    width: 100%
  }
  .water-top {
    width: 100%
  }
  .water-box {
    display: block;width: 100%;
  }
  .water-bottom {
    width: 100%
  }
  .forward-box {
    display: block;
  }
  .forward-top{
    width: 100%
  }
  .forward-bottom{
    width: 100%
  }
  .pring {
    width: 100%
  }
  .screenfull-svg {
    display: inline-block;
    cursor: pointer;
    fill: #5a5e66;;
    width: 20px;
    height: 20px;
    vertical-align: 10px;
  }
  //.swiper-top {
  //  height: 2000px;
  //  overflow: hidden;
  //  overflow-y: auto;
  //}
  //.swiper-container {
  //  width: 100%;;
  //}
  //
  .swiper-slide {
    width: 50%!important;
    margin-right: 90px !important;
    display: block;
  }
  //.swiper-wrapper {
  //  width: 100%;
  //  display: block;
  //
  //}
  //.swiper-wrapper2 {
  //  //display: inline-flex;
  //  //animation: swiperrun 100s linear infinite;
  //}
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next1';
  }
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 20%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
  }
  //.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  //  content: 'prev';
  //}
  .swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
  .swiper-button-prev {
    left: 10px;
    right: auto;
  }
  .swiper-button-next {
    right: 10px;
    left: 10px;
    background-color: rgb(255,255,255,0);
  }
  .fullScreen {
    display: none;
  }
  .active {
    display: block;
  }
  .active2 {
    display: none;
  }
  .activeNone {
    display: none;
  }
  .painting {
    display: flex;
    justify-content: space-between;
    font-size: 78px;
    color: #333;
    margin-bottom: 10px;
  }
  .painting .name {
    display: inline-block;
  }
  .painting .name:first-child {
    //width: 150px;
    //overflow: hidden;
  }
  .painting .name:first-child div {
    width: 150px;
    overflow: hidden; white-space: nowrap;
  }
  .device-box {
    display: flex;
    border-left: 1px solid #8BB3FF;
    border-right: 1px solid #8BB3FF;
    border-top: 1px solid #8BB3FF;
    border-bottom: 0px solid #8BB3FF;
    align-items: center;
  }
  .device-box:last-child {
    border-bottom: 1px solid #8BB3FF;
  }
  .deviceTitle {
    width: 25%;
    font-size: 78px;
    text-align: center;
    //line-height: 114px;
    background-color: rgba(255,255,255,0);
    overflow: hidden;
    //text-overflow: ellipsis; white-space: nowrap;
  }
  .deviceCenter {
    width: 80%;
  }
  .device-matter span {
    display: inline-block;
    font-size: 78px;
    text-align: center;
    padding: 120px 0;
    border-left: 1px solid #8BB3FF;
    border-bottom: 1px solid #8BB3FF;
    height: 90px;
    line-height: 32px;
  }
  .device-matter span:nth-child(1){
    width: 26%;
  }
  .device-matter span:nth-child(2){
    width: 37%;
  }
  .device-matter span:nth-child(3){
    width: 37%;
  }
  .device-matter:last-child span {
    border-bottom: 0
  }
  .abnormal .device-matter span{
    width: 25%;
  }
  .abnormal .device-matter:first-child span {
    border-top: 1px solid #8BB3FF;
  }
  .abnormal .device-matter:last-child span {
    border-bottom: 1px solid #8BB3FF;
  }
  .abnormal .device-matter span:last-child{
    border-right: 1px solid #8BB3FF;
  }
  .device-matter1 span {
    line-height: 55px;
    height: 60px;
  }
  .abnormal .device-matter4 span{
    width: 33.3%;
  }
  //.abnormal .device-matter4 span:nth-child(3){
  //  width: 27%;
  //}
  //.abnormal .device-matter4 span:nth-child(4){
  //  width: 27%;
  //}
  .device-matter4 span {
    height: 90px;
    line-height: 32px;
  }
  .device-matter2 {
    width: 100%;
    line-height: 90px;
    height: 245px;
  }

  .device-matter2 span{
    background-color: rgba(255,255,255,0.6);
    height: 90px;
    line-height: 32px;
    //width: 25.3% !important;
    overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis; white-space: nowrap;
  }
  .device-matter5 {
    width: 100%;
    line-height: 90px;
    height: 245px;
  }

  .device-matter5 span{
    background-color: rgba(255,255,255,0.6);
    height: 90px;
    line-height: 32px;
    //width: 25.3% !important;
    overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis; white-space: nowrap;
  }
  .device-matter3 span{
    background-color: rgba(255,255,255,0);
  }

  .aaa::-webkit-scrollbar { width: 0 !important }
  /*IE 10+*/
  .aaa { -ms-overflow-style: none; }
  /*Firefox*/
  .aaa { overflow: -moz-scrollbars-none; }
  .no-data {
    font-size: 78px;
    text-align: center;
    line-height: 800px;
  }
  .no-data-line {
    line-height: 505px;
  }
  .no-data-top {
    font-size: 78px;
    text-align: center;
    line-height: 265px;
  }
  .no-data-top2 {
    font-size: 78px;
    text-align: center;
    line-height: 265px;
  }
  .no-data-list {
    font-size: 78px;
  }
  .deviceTitle::before{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
    content: '';
  }
  .tionY {
    //height: 3000px;
    //transition: all 0.5s;
    animation: marquee 40s linear infinite
  }
  .tionY2 {
    //height: 3000px;
    //transition: all 0.5s;
    animation: marquee2 10s linear infinite
  }
  .marquee {
    //animation-delay: -5s;
    animation: marquee 100s linear infinite;
  }
  .marquee2 {
    //animation-delay: -5s;
    animation: marquee2 100s linear infinite;
  }
  //.marquee:hover {
  //  animation-play-state: paused;
  //}
  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translateY(-70%); //这里不是-100%！
      //transform: translate3d(0, -2201px, 0)
    }
  }
  @keyframes marquee2 {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translateY(-57%); //这里不是-100%！
      //transform: translate3d(0, -2201px, 0)
    }
  }
  @keyframes swiperrun {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translateX(-90%); //这里不是-100%！
      //transform: translate3d(0, -2201px, 0)
    }
  }
  .width-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
  }
  .width-title2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
  }
  .width-title3 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
  }
  .width-top {
    width: 1300px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-top2 {
    width: 2400px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-top3 {
    width: 900px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-top4 {
    width: 800px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-top5 {
    width: 1500px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-top6 {
    width: 900px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .height-503 {
    height: 1500px;
    width: 100%;
    margin-bottom: 200px;
  }
  .height-501 {
    height: 1600px;
  }
  .height-231 {
    height: 1200px;
  }
  .style-shui {
    background-color: rgba(255,255,255,0.4);padding: 15px;border-top: 1px solid #8BB3FF;box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);
  }
  .height-56 {
    height: 900px;
  }
}
@media screen and (min-width:630px) and (max-width:800px) {
  .swiper-slide {
    width: 48% !important;
    display: inline-block;
    margin-right: 10px !important;
    text-align: center;
  }
  .swiper-container {
    width: 100%;;
  }
  .reason {
    width: 45%;
    display: inline-block;
  }
}
@media screen and (min-width:500px) and (max-width:800px) {

  .height-501 {
    height: 1000px;
  }
}
</style>

